import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
    return (
        <div className="sidebar">
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/stat-history">Stat History</Link></li>
                <li><Link to="/weekly-stat">Weekly Stat</Link></li>
            </ul>
        </div>
    );
}

export default Sidebar;