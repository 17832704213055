import React from 'react';
import './Hamburger.css';

function Hamburger() {
    return (
        <div className="hamburger">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </div>
    );
}

export default Hamburger;