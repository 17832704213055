import React, { useEffect, useState } from 'react';
import artistData from '../data/aotw/artists.json';
import ArtistOfTheWeek from '../components/ArtistOfTheWeek';
import UserPill from '../components/UserPill';

function Home() {
  // Artist of the week
  const currentWeek = 2;
  const artistsForCurrentWeek = artistData.filter(artist => artist.week === currentWeek);

  // User profile
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://spoti-pals-1950205a25ce.herokuapp.com/api/users');
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          console.error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div>
      {/* First section: Weekly recommendations */}
      <h2>Weekly Recs</h2>
      <div className = "artists-container">
        {artistsForCurrentWeek.map((artist, index) => (
          <ArtistOfTheWeek
            key={index}
            name={artist.name}
            description={artist.description}
            imageUrl={artist.imageUrl}
            spotifyUrl={artist.spotifyUrl}
          />
        ))}
      </div>

      {/* Second section: Some registered users */}
      <h2>Make some friends!</h2>
      <div className="users-container">
        {users.map(user => (
          <UserPill key={user.username} username={user.username} />
        ))}
      </div>
    </div>
  );
}

export default Home;