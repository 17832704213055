import React from 'react';
import './ArtistOfTheWeek.css';

function ArtistOfTheWeek({ name, description, imageUrl, spotifyUrl }) {
    return (
        <div className="artist-of-the-week">
            <img src={imageUrl} alt={name}/>
            <div>
                <h2>
                    <a href={spotifyUrl} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
                        {name}
                    </a>
                    </h2>
                <p>
                    {description}
                </p>
            </div>
        </div>
    );
};

export default ArtistOfTheWeek;