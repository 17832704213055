import React, { useState } from 'react';
import './CommentForm.css'

function CommentForm() {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    const handleCommentChange = (event) => {
        setNewComment(event.target.value);
    };

    const handleCommentSubmit = (event) => {
        event.preventDefault();
        if (newComment.trim()) {
            setComments([...comments, newComment]);
            setNewComment('');
        }
    };

    return (
        <div className="comment-form">
            <h2>Comments</h2>
            <form onSubmit={handleCommentSubmit}>
                <textarea 
                    value={newComment}
                    onChange={handleCommentChange}
                    placeholder="Enter your comment"
                    rows="4"
                    cols="50"
                />
                <br />
                <button type="submit">Submit</button>
            </form>
            <div className="comments-list">
                {comments.map((comment, index) => (
                    <div key={index} className="comment">
                        {comment}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CommentForm;