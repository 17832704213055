import React from 'react';
import Dropdown from '../components/Dropdown';
import './About.css'; // Import the CSS file

function About() {
    return (
        <div className="about-container">
            <h2>About</h2>
            <p>This is my about page. About what. I don't know yet</p>
            
            <Dropdown title="Privacy and Security">
                <h3>Passwords</h3>
                <p>I take your privacy seriously enough. #Datenschutz. When you register I don't store any plain text passwords, instead they are hashed and encrypted. Standard secure encryption algorithms are used to prevent any privacy issues. In short, I don't know your password either.</p>

                <h3>Your Data</h3>
                <p>Well, I do have all of your Spotify data, including the following:</p>
                <ul>
                    <li><strong>Timestamp (ts):</strong> The time when a track was played.</li>
                    <li><strong>Username:</strong> Your Spotify username.</li>
                    <li><strong>Platform:</strong> The device used to play the track.</li>
                    <li><strong>Duration (ms_played):</strong> The duration of the play.</li>
                    <li><strong>IP Address (ip_addr_decrypted):</strong> Decrypted IP address (Note: I am not entirely sure what this represents).</li>
                    <li><strong>User Agent (user_agent_decrypted):</strong> Decrypted user agent information (Note: This is also unclear).</li>
                    <li><strong>Track Information:</strong> Including track name, album name, and artist name.</li>
                    <li><strong>Spotify URIs:</strong> Unique identifiers for tracks and episodes on Spotify.</li>
                    <li><strong>Playback Details:</strong> Information such as shuffle mode, skip actions, and whether playback was offline or in incognito mode.</li>
                </ul>
                <p>Your data is stored online in a secure database.</p>
            </Dropdown>
        </div>
    );
}

export default About;