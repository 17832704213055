import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Hamburger from './components/Hamburger';
import RegistrationForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import StatHistory from './pages/StatHistory';
import WeeklyStat from './pages/WeeklyStat';
import FriendPage from './pages/FriendPage';
import Profile from './pages/Profile';
import PublicProfile from './pages/PublicProfile';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null); // Reference for the sidebar

  console.log('API URL:', process.env.REACT_APP_API_URL);

  // Toggle the sidebar's visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Close sidebar if click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && ! sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Forget event listener after untoggle
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Header /> {/* The header of the website */}

        {/* Hamburger Icon, conditionally rendered */}
        {!isSidebarOpen && (
        <div onClick={toggleSidebar}>
          <Hamburger />
        </div>
        )}

        {/* Sidebar container with conditional class for visibility */}
        <div 
          ref={sidebarRef}
          className={`sidebar-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
          <Sidebar />
        </div>

        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/stat-history" element={<StatHistory />} />
            <Route path="/weekly-stat" element={<WeeklyStat />} />
            <Route path="/friend/:name" element={<FriendPage />} /> {/* Dynamic Route */}
            <Route path="/register" element={<RegistrationForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/public-profile/:username" element={<PublicProfile />} />
          </Routes>
        </div>

      </div>

      
    </Router>
  );
}

export default App;
