import React from 'react';
import FriendPill from '../components/FriendPill';
// import ArtistOfTheWeek from '../components/ArtistOfTheWeek';
import friendData from '../data/FriendData/friends.json';

function WeeklyStat() {
  // List of friends
  return (
    <div>
      <h2>Exposing my friends</h2>
      <div className = "friends-container">
        {friendData.map((friend, index) => (
            <FriendPill key={index} 
            name={friend.name}
            status={friend.status} />
        ))}
      </div>
    </div>
  );
}


export default WeeklyStat;