import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';

import './Header.css';

function Header () {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <header className="header">
            <h1>
                <Link to="/" className="title-link">SpotiPals</Link>
            </h1>

            <nav className="nav-links">
                <div className="settings-container">
                    <FaCog className="settings-icon" onClick={toggleDropdown} />
                    {dropdownVisible && (
                        <div className="dropdown-menu">
                            <Link to="/register" className="auth-link">Register</Link>
                            <Link to="login" className="auth-link">Login</Link>
                            <Link to="/profile" className="profile-link">Profile</Link>
                        </div>
                    )}
                    {/* This is a button section */}
        
                </div>
            </nav>
        </header>
    )
}

export default Header;