// src/pages/Profile.js
import React, { useEffect, useState } from 'react';
import './Profile.css';

function Profile() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://spoti-pals-1950205a25ce.herokuapp.com/api/profile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data);
        } else {
          setError('Failed to load profile');
        }
      } catch (error) {
        setError('Error loading profile');
      }
    };

    fetchUserData();
  }, []);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!user) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="profile-container">
      <div className="profile-card">
        <h1 className="profile-title">Profile</h1>
        <p className="profile-item"><strong>Username:</strong> {user.username}</p>
        <p className="profile-item"><strong>Email:</strong> {user.email}</p>
        <p className="profile-item"><strong>First Name:</strong> {user.first_name}</p>
        <p className="profile-item"><strong>Last Name:</strong> {user.last_name}</p>
      </div>
    </div>
  );
}

export default Profile;