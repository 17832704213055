import React from 'react';
import { Link } from 'react-router-dom';
import './FriendPill.css';

function FriendPill({ name, status }) {
    return (
        <div className="friend-pill">
            <div>
                <h2>
                    <Link to={`/friend/${name}`}>{name}</Link>
                </h2>
                <p><b>Status:</b> {status} </p>
            </div>
        </div>
    );
}

export default FriendPill;