import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './PublicProfile.css';

function PublicProfile() {
    const { username } = useParams();
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPublicUserData = async () => {
            try {
                const response = await fetch(`https://spoti-pals-1950205a25ce.herokuapp.com/api/public-profile/${username}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setUser(data);
                } else {
                    setError('Failed to load public profile');
                }
            } catch (err) {
                setError('Error loading public profile');
            }
        };

        fetchPublicUserData();
    }, [username]);

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (!user) {
        return <div className="loading-message">Loading...</div>;
    }

    return (
        <div className="public-profile-container">
            <div className="public-profile-card">
                <h1 className="public-profile-title">Public Profile</h1>
                <p className="public-profile-item"><strong>Username:</strong> {user.username}</p>
            </div>
        </div>
    );
}

export default PublicProfile;