// src/components/UserPill.js
import React from 'react';
import { Link } from 'react-router-dom';
import './UserPill.css';

function UserPill({ username }) {
  return (
    <Link to={`/public-profile/${username}`} className="user-pill">
      {username}
    </Link>
  );
}

export default UserPill;