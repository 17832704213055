import React from 'react';
import { useParams } from 'react-router-dom';
import friendsData from '../data/FriendData/friends.json';
import CommentForm from '../components/CommentForm';

function FriendPage() {
    const { name } = useParams();
    const friend = friendsData.find(friend => friend.name === name);

    if (!friend) {
        return <div>Friend not found</div>; // Handle case where the friend isn't found
    }

    return (
        <div>
            <h1>{friend.name}'s Weekly Stats</h1>
            <p>Welcome, {friend.name}, to your personalized stat page. Or welcome, curious observer, as well.</p>
            <div className="stats-container">
                <h2>Status: {friend.status}</h2> {/* Display the friend's status */}
                <h2>Stat example 1</h2>
                {/* Add more stats or components here as needed */}
            </div>

            <CommentForm />

        </div>
    );
}

export default FriendPage;